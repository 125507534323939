import React from 'react';
import { VStack, Box, Text, Link, Center } from '@chakra-ui/react';
import { BottomIcons } from '../BottomIcons';

export const Question = () => {
	const handleClick = () => {
		window.open('mailto:jenna@vinemedical.com');
	};

	return (
		<Center marginTop='10px'>
			<VStack>
				<Box>
					<Text className='mobile-footer-bottom-headline'>
						Have a question for Dr. S?
					</Text>
				</Box>
				<Box>
					<Text className='mobile-footer-bottom-text'>
						Send her a message{' '}
						<Link
							onClick={handleClick}
							className='mobile-footer-bottom-link'
							color='#e2796f'
							_hover={{ color: '#CB5E54' }}
						>
							here.
						</Link>
					</Text>
				</Box>
				<Box>
					<BottomIcons />
				</Box>
			</VStack>
		</Center>
	);
};
