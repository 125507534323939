import React from 'react';
import { Center, Box, HStack, Image, Text } from '@chakra-ui/react';

export const ServicesPageTemplate = (props) => {
	return (
		<Center className='services-temp-center'>
			<Box className='services-temp-topbox'>
				<HStack className='services-temp-hstack'>
					<Box className='services-temp-box'>
						<Box className='services-temp-copy'>
							<Text className='services-temp-headline'>{props.headline}</Text>
							<Text className='services-temp-body' marginBottom='20px'>
								{props.copy1}
							</Text>
							<Text className='services-temp-body' marginBottom='20px'>
								{props.copy2}
							</Text>
						</Box>
						<Box>
							<Box
								as='button'
								color='#e2796f'
								bg='rgba(0, 0, 0, 0)'
								borderColor='#e2796f'
								borderWidth='1px'
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								_hover={{
									bg: '#e2796f',
									color: 'white',
									borderColor: '#e2796f',
									borderWidth: '1px'
								}}
								className='services-temp-button'
							>
								{props.button}
							</Box>
						</Box>
					</Box>
					<Box className='services-temp-img-box'>
						<Center>
							<Image src={props.img} className='services-temp-img' />
						</Center>
					</Box>
				</HStack>
			</Box>
		</Center>
	);
};
