import React from 'react';
import { Text, Box, VStack, Center, Image } from '@chakra-ui/react';

export const Testimonial = (props) => {
	const { name, img1, img2, testbody } = props;
	return (
		<Center>
			<VStack>
				<Box>
					<Text className='testimonial-name'>{name}</Text>
				</Box>
				<Box>
					<Image src={img1} className='testimonial-quote' />
				</Box>
				<Box>
					<Center>
						<Text className='testimonial-body'>{testbody}</Text>
					</Center>
				</Box>
				<Box>
					<Image src={img2} className='testimonial-quote' />
				</Box>
			</VStack>
		</Center>
	);
};
