import React from 'react';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Link,
	IconButton,
	Icon
} from '@chakra-ui/react';
import { HiMenu } from 'react-icons/hi';

export const NavbarMenu = () => {
	const handleClick = () => {
		window.open('mailto:jenna@vinemedical.com?subject=Consultation Request');
	};

	return (
		<Menu autoSelect='false'>
			<MenuButton
				as={IconButton}
				aria-label='Options'
				icon={<Icon as={HiMenu} w={10} h={10} />}
				size='md'
				bg='white'
			/>
			<MenuList textAlign='center' height='auto'>
				<MenuItem>
					<Link
						color='black'
						className='mobile-nav-menu'
						href='/services'
						_hover={{ color: 'black' }}
					>
						Services
					</Link>
				</MenuItem>

				<MenuItem textAlign='center'>
					<Link
						color='black'
						className='mobile-nav-menu'
						href='/about'
						_hover={{ color: 'black' }}
					>
						About
					</Link>
				</MenuItem>
				<MenuItem textAlign='center'>
					<Link
						color='black'
						className='mobile-nav-menu'
						href='/contact'
						_hover={{ color: 'black' }}
					>
						Contact
					</Link>
				</MenuItem>
				<MenuItem textAlign='center'>
					<Link
						color='#e2796f'
						className='mobile-nav-menu'
						onClick={handleClick}
					>
						Book A Consultation
					</Link>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
