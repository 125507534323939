import React from 'react';
import { Box, Text, Center, VStack } from '@chakra-ui/react';
import apptimg from './Appointments@3x.png';
import { appt } from '../../copy';

export const Appointment = () => {
	const handleClick = () => {
		window.open('mailto:jenna@vinemedical.com?subject=Consultation Request');
	};

	return (
		<Center className='appt-center'>
			<Box className='appt-box' style={{ backgroundImage: `url(${apptimg})` }}>
				<Box className='appt-box2'>
					<VStack align='left' className='appt-content'>
						<Box>
							<Text className='appt-headline'>{appt.headline}</Text>
						</Box>
						<Box>
							<Text className='appt-copy'>{appt.copy}</Text>
						</Box>
						<Box>
							<Box
								as='button'
								onClick={handleClick}
								color='#e2796f'
								bg='rgba(0, 0, 0, 0)'
								borderColor='#e2796f'
								borderWidth='1px'
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								_hover={{
									bg: '#e2796f',
									color: 'white',
									borderColor: '#e2796f',
									borderWidth: '1px'
								}}
								className='appt-button'
							>
								{appt.button}
							</Box>
						</Box>
					</VStack>
				</Box>
			</Box>
		</Center>
	);
};
