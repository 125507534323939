import React from 'react';
import YouTube from 'react-youtube';

export const Video = (props) => {
	const opts = {
		height: props.h,
		width: props.w,
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0
		}
	};

	return (
		<div>
			<YouTube videoId={props.id} opts={opts} />
		</div>
	);
};
