import React from 'react';
import { Flex, Center, Spacer, Image, Link } from '@chakra-ui/react';
import Logo from './Logo@3x.png';
import { NavbarMenu } from './NavbarMenu';

export const Navbar = () => {
	return (
		<Center>
			<Flex className='mobile-nav-flex'>
				<Center>
					<Link href='/'>
						<Image src={Logo} width='550px' />
					</Link>
				</Center>

				<Spacer />
				<Center>
					<NavbarMenu />
				</Center>
			</Flex>
		</Center>
	);
};
