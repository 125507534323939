import React from 'react';
import { Box } from '@chakra-ui/react';
import { CustomForm } from './CustomForm';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

export const BottomForm = () => {
	const url =
		'//drs.us1.list-manage.com/subscribe/post?u=13917b1c9ee8224399a151960&amp;id=26a1ec7220';
	return (
		<Box>
			<MailchimpSubscribe
				url={url}
				render={({ subscribe, status, message }) => (
					<CustomForm
						status={status}
						message={message}
						onValidated={(formData) => subscribe(formData)}
					/>
				)}
			/>
		</Box>
	);
};
