import React from 'react';
import { Center, Box, Image, HStack } from '@chakra-ui/react';
import { MainContent } from './MainContent';

import PodImage from './MainPod_Image@3x.png';

export const MainPod = () => {
	return (
		<Center>
			<Box className='main-pod-box'>
				<Center>
					<HStack className='main-pod-hstack mph-left' spacing={20}>
						<Box className='main-pod-content-box'>
							<MainContent />
						</Box>
						<Box className='main-pod-img-box mph-right'>
							<Center>
								<Image src={PodImage} className='main-pod-img' />
							</Center>
						</Box>
					</HStack>
				</Center>
			</Box>
		</Center>
	);
};
