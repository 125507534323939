import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';
import { mainpod } from '../../../copy';

export const MainContent = () => {
	return (
		<VStack className='main-container'>
			<Box textAlign='center'>
				<Text className='main-headline'>{mainpod.headline}</Text>
			</Box>
			<Box textAlign='center'>
				<Text className='main-body'>{mainpod.body}</Text>
			</Box>
			<Box
				as='button'
				color='#e2796f'
				bg='rgba(0, 0, 0, 0)'
				borderColor='#e2796f'
				borderWidth='1px'
				transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
				_hover={{
					bg: '#e2796f',
					color: 'white',
					borderColor: '#e2796f',
					borderWidth: '1px'
				}}
				className='main-button'
			>
				{mainpod.button}
			</Box>
		</VStack>
	);
};
