import React from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';
import { Service } from './Service';
import COimg from './Images/CO@3x.png';
import POimg from './Images/PO@3x.png';
import HRimg from './Images/HR@3x.png';
import PRimg from './Images/PR@3x.png';
import { _services } from '../../../copy';
import { ServicesButton } from './ServicesButton';

export const Services = () => {
	const { hr, pr, co, po } = _services;
	return (
		<Center className='mobile-services-center'>
			<VStack>
				<Box className='service-hstack-box'>
					<Service
						img={COimg}
						headline={co.headline}
						copy={co.body}
						button={<ServicesButton url={co._url} />}
					/>
				</Box>
				<Box className='service-hstack-box'>
					<Service
						img={POimg}
						headline={po.headline}
						copy={po.body}
						button={<ServicesButton url={po._url} />}
					/>
				</Box>
				<Box className='service-hstack-box'>
					<Service
						img={HRimg}
						headline={hr.headline}
						copy={hr.body}
						button={<ServicesButton url={hr._url} />}
					/>
				</Box>
				<Box className='service-hstack-box'>
					<Service
						img={PRimg}
						headline={pr.headline}
						copy={pr.body}
						button={<ServicesButton url={pr._url} />}
					/>
				</Box>
			</VStack>
		</Center>
	);
};
