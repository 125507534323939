import React from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';
import { Services_COPO } from './Services_COPO';
import { Services_HRPR } from './Services_HRPR';

export const Services = () => {
	return (
		<Center className='services-center'>
			<VStack>
				<Box className='services-vstack'>
					<Services_COPO />
				</Box>
				<Box>
					<Services_HRPR />
				</Box>
			</VStack>
		</Center>
	);
};
