import React from 'react';
import { Services } from './Services/Services';
import { HomeDivider } from '../Homepage/HomeDivider';
import { Appointment } from '../Homepage/Appointment/Appointment';

export const ServicesPage = () => {
	return (
		<div>
			<Services />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
