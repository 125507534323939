import React from 'react';
import { Center, Text, Box } from '@chakra-ui/react';

export const TestimonialHeader = () => {
	return (
		<Center className='testimonial-header-center'>
			<Box width='80%'>
				<Text align='center' className='testimonial-header'>
					HEAR FROM HER CLIENTS
				</Text>
			</Box>
		</Center>
	);
};
