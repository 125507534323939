import React from 'react';
import quote1 from './quote_rightside@3x.png';
import quote2 from './quote_upside@3x.png';
import { Testimonial } from './Testimonial';
import { Center, HStack, Box, VStack } from '@chakra-ui/react';
import { testimonials } from '../../copy';
import ReactPlayer from 'react-player';
import MediaQuery from 'react-responsive';

export const Testimonials = () => {
	return (
		<Center className='testimonials-center'>
			<VStack>
				<Box>
					<Center>
						<HStack className='testimonials-hstack' spacing='60px'>
							{testimonials.map((test) => (
								<Box className='testimonials-box' key={test.key}>
									<Testimonial
										img1={quote1}
										img2={quote2}
										name={test.name}
										testbody={test.body}
									/>
								</Box>
							))}
						</HStack>
					</Center>
				</Box>
				<Box>
					<MediaQuery minWidth={1376}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='900px'
								height='506px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={1375} minWidth={1243}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='850px'
								height='478px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={1242} minWidth={1088}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='750px'
								height='422px'
							/>
						</Center>
					</MediaQuery>
				</Box>
			</VStack>
		</Center>
	);
};
