import React, { useEffect } from 'react';
import {
	Box,
	Input,
	FormControl,
	FormLabel,
	Alert,
	AlertIcon,
	FormHelperText,
	Progress,
	Center,
	VStack,
	Text,
	useToast
} from '@chakra-ui/react';
import { emailModal } from '../../copy';

export const CustomForm = ({ status, message, onValidated, onClose }) => {
	useEffect(() => {
		checkStatus();
	}, [status]);

	// const close = () => {
	// 	if (status === 'success') {
	// 		onClose();
	// 	}
	// };

	const checkStatus = () => {
		if (status === 'success') {
			localStorage.setItem('subscribed', 'true');
		}
	};
	let email, fname, lname;
	const submit = async () => {
		if (email && fname && lname && email.value.indexOf('@') > -1) {
			const validate = await onValidated({
				EMAIL: email.value,
				FNAME: fname.value,
				LNAME: lname.value
			});
			if (validate) {
				onClose();
			}
		}
	};
	return (
		<Center marginLeft='60px' marginTop='50px'>
			<VStack alignItems='center'>
				<Box id='mc-embed-signup'>
					<FormControl id='mce-FNAME' isRequired>
						<FormLabel>First Name</FormLabel>
						<Input
							placeholder='Your First Name'
							width='350px'
							bg='white'
							type='text'
							ref={(node) => (fname = node)}
						/>
					</FormControl>
				</Box>
				<Box>
					<FormControl id='mce-LNAME' isRequired>
						<FormLabel className='modal-form-input'>Last Name</FormLabel>
						<Input
							placeholder='Your Last Name'
							type='text'
							width='350px'
							bg='white'
							ref={(node) => (lname = node)}
						/>
					</FormControl>
				</Box>
				<Box>
					<FormControl id='mce-EMAIL' isRequired>
						<FormLabel className='modal-form-input'>Email address</FormLabel>
						<Input
							placeholder='Your Email'
							type='text'
							width='350px'
							bg='white'
							ref={(node) => (email = node)}
						/>
						<FormHelperText color='white'>
							We'll never share your email.
						</FormHelperText>
					</FormControl>
				</Box>
				<Center>
					<Box
						as='button'
						transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
						color='white'
						bg='#283831'
						_hover={{
							bg: '#456255',
							color: 'white'
						}}
						className='modal-button'
						onClick={submit}
					>
						{emailModal.button}
					</Box>
				</Center>
				<Box className='modal-enter-fields'>
					<Text>*Please enter all fields.</Text>
				</Box>
				{status === 'sending' && (
					<Box marginTop='10px'>
						<Progress size='xs' colorScheme='blue' isIndeterminate />
					</Box>
				)}
				{status === 'error' && (
					<Box marginTop='20px'>
						<Alert status='error' borderRadius='25px'>
							<AlertIcon />
							This email has already been submitted.
						</Alert>
					</Box>
				)}
				{status === 'success' && (
					<Box marginTop='20px' fontSize='12px'>
						<Alert status='success' borderRadius='25px'>
							<AlertIcon />
							Thanks for your interest!
						</Alert>
					</Box>
				)}
			</VStack>
		</Center>
	);
};
