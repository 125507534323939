import React from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';
import { TopCenter } from './TopCenter';
import { BottomCenter } from './BottomCenter';

export const TheCenter = () => {
	return (
		<Center>
			<VStack>
				<Box>
					<TopCenter />
				</Box>
				<Box>
					<BottomCenter />
				</Box>
			</VStack>
		</Center>
	);
};
