import React from 'react';
import { emailModal } from '../../../copy';
import { Box, VStack, Text, Center } from '@chakra-ui/react';

export const TopCopy = () => {
	return (
		<Center>
			<VStack alignItems='center' className='modal-left-box'>
				<Box>
					<Center>
						<Text text-align='center' className='modal-hl'>
							{emailModal.headline}
						</Text>
					</Center>
				</Box>
			</VStack>
		</Center>
	);
};
