import React, { useEffect } from 'react';
import { MobileServicesPageTemplate } from '../MobileServicesPageTemplate';
import { _services } from '../../copy';
import image from './Performance_Mobile@2x.png';
import { Youtube_PO } from '../Youtube/Youtube_PO';
import { HomeDivider } from '../../Homepage/HomeDivider';
import { Appointment } from '../../Mobile/Homepage/Appointment/Appointment';
import { Services } from '../Services/Services';

export const PerformancePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { po } = _services;
	return (
		<div>
			<MobileServicesPageTemplate
				img={image}
				headline={po.headline}
				copy1={po.copy[0]}
				copy2={po.copy[1]}
				button={_services.pageButton}
			/>
			<Youtube_PO />
			<HomeDivider />
			<Services top={`services-page-top-mobile`} />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
