import React from 'react';
import { Box, Center, Text, VStack, HStack } from '@chakra-ui/react';
import { Video } from './Video';
import { _services } from '../../copy';
import MediaQuery from 'react-responsive';

export const Youtube_CO = () => {
	const { co } = _services;
	return (
		<Center className='youtube-center'>
			<VStack className='youtube-vstack'>
				<Box className='youtube-headline' text-decoration-skip-ink='none'>
					<Text>{co.youtube_hl}</Text>
				</Box>
				<Box className='youtube-hstack'>
					<Center>
						<HStack spacing={10}>
							<MediaQuery minWidth={1243}>
								<Box>
									<Video
										id={co.youtube_id[0]}
										h={_services.youtube_h}
										w={_services.youtube_w}
									/>
								</Box>
								<Box>
									<Video
										id={co.youtube_id[1]}
										h={_services.youtube_h}
										w={_services.youtube_w}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={1242}>
								<Box>
									<Video
										id={co.youtube_id[0]}
										h={_services.youtube_h2}
										w={_services.youtube_w2}
									/>
								</Box>
								<Box>
									<Video
										id={co.youtube_id[1]}
										h={_services.youtube_h2}
										w={_services.youtube_w2}
									/>
								</Box>
							</MediaQuery>
						</HStack>
					</Center>
				</Box>
				<Box classname='youtube-button-box'>
					<Box
						onClick={() =>
							window.open(
								'https://www.youtube.com/channel/UC1RFVKncx0pSqHfiTekv77A/?sub_confirmation=1',
								'_blank'
							)
						}
						as='button'
						color='#e2796f'
						bg='rgba(0, 0, 0, 0)'
						borderColor='#e2796f'
						borderWidth='1px'
						transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
						_hover={{
							bg: '#e2796f',
							color: 'white',
							borderColor: '#e2796f',
							borderWidth: '1px'
						}}
						className='youtube-button'
					>
						{_services.youtubeButton}
					</Box>
				</Box>
			</VStack>
		</Center>
	);
};
