import React from 'react';
import { emailModal } from '../../../copy';
import { Box, VStack, Text } from '@chakra-ui/react';

export const LeftContent = () => {
	return (
		<VStack alignItems='left' className='modal-left-box'>
			<Box>
				<Text className='modal-left-hl'>{emailModal.headline}</Text>
			</Box>
			<Box>
				<Text className='modal-left-copy'>{emailModal.copy}</Text>
			</Box>
		</VStack>
	);
};
