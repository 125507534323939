export const homepage = {
  navButton: `BOOK A CONSULTATION` + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0` + `→`,
};

export const contact = {
  headline: 'We’d love to hear from you',
  copy: [
    'Interested in learning more about how cellular and longevity medicine from Dr. Suzanne Ferree can help optimize your performance? Take the first step and contact us! The offices of Dr. S are open 8AM - 4PM EST, Monday through Friday. We can’t wait to hear from you!',
    'Contact us today at (404) 446-3600 or email us at ',
  ],
  button1: `Give Us A Call` + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0` + `→`,
  button2: `Learn How Dr. S Can Help` + `\xa0\xa0\xa0\xa0` + `→`,
};

export const mainpod = {
  headline: `Performance & longevity therapies from Dr. Suzanne Ferree`,
  body: `Peptide therapy and cellular efficiency treatments from one of the industry’s leading longevity medicine specialists. Dr. S is now accepting limited appointments on a first-come, first-serve basis.`,
  button: `BOOK A CONSULTATION` + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0` + `→`,
};

export const biomel = {
  headline: 'Melissa Selby',
  subhead: 'FNP-C, ABAAHP',
  copy: 'Melissa is an Atlanta native who attended Westminster high school, graduating from UGA with honors. She received her Masters at Mercer University, Sigma Cum Laude. Melissa is Board Certified and Fellowship trained in Functional and Integrative Medicine by the American Academy of Antiaging and Regenerative Medicine. She has her Peptide Certification from the American Academy of Antiaging and Regenerative Medicine as well. She is happily married to Scott and has two beautiful boys, Baker and Carson. She adores time with family, playing tennis and taking Peloton classes.',
};

export const bio = {
  headline: 'MEET DR. S',
  copy: [
    `I remember coming out of my first exposure to functional medicine like the scales had fallen from my eyes, and I could never unknow what I now knew. But I had very little idea how to apply all that I had learned to the patients in my practice. I have spent thousands of dollars on postgraduate schooling to become arguably the most sought-after provider of cellular, longevity and performance medicine around. I am ready to share that knowledge with you.`,
    `While in residency, I achieved the Society of Teachers of Medicine (STFM) Award, which like the Medal of Honor is only given when the person demonstrates exceptional performance. I am board certified in functional medicine through the American Academy of Anti-Aging and Regenerative Medicine (A4M); this is the pinnacle of education in longevity and regenerative medicine. I also studied post fellowship and received advanced certification in Endocrinology, then I studied pellet therapy through Dr. Rebecca Glazer and BioTe, the leading researchers in pellet therapy for hormone replacement. I was in the first class to graduate the Peptide Certification through A4M, and due to my passion and mastery of the science of cellular medicine, I was selected as part of their faculty for all subsequent peptide certification courses. In the last 7 years, I've participated in Masterminds through the International Peptide Society (IPS) and Seeds Scientific Research and Performance (SSRP). I have been honored to serve as faculty for several leading organizations in the cellular, performance and longevity space, including IPS, the American College of Advancements in Medicine (ACAM), BioTe and SSRP. My popularity on SuperHumanRadio's PepTalk, Jay Campbell, and Biohacking SuperHuman Performance surprised even me and has resulted in repeated invitations. As you can see, my passions are teaching and learning.`,
    `This residency is a new venture for me, and I expect it will mature along with my continuing education. This will be the inaugural program in the Southeast, and I'm excited to bring it to you!`,
    `In order for this specialized program to optimize your time with me and still allow me to see my own patients who are my constant source of education, I’ve chosen to limit to 3 providers per quarter. Exceptional NPs, PAs, and MDs are all welcome, even out of state.`,
  ],
  follow: 'FOLLOW DR. S',
};

export const _services = {
  co: {
    headline: `Cognitive Optimization`,
    body: `Begin the path to repairing impaired cognitive states, or optimize cognitive function to be at your best, every single day.`,
    copy: [
      `Begin the path to repairing impaired cognitive states with cellular therapies prescribed by Dr. S. Her cognitive optimization therapies treat symptoms stemming from a variety of disease states including dementia, parkinsons, stroke, anoxic brain injury, and traumatic brain injury.`,
      `Dr. S also specializes in therapies tailored to CEOs, c-suite execs, and patients who require maximum daily cognitive function. Her cellular therapies can optimize cognitive performance so her patients can be at their best, every single day.`,
    ],
    _url: `cognitive-optimization-therapy`,
    youtube_hl: `Dr. S on Peptide Therapy`,
    youtube_id: ['aHAsMZDsmQI', 'c0i1p3RdMLc'],
  },
  po: {
    headline: `Performance Optimization`,
    body: `Be your best professional, build your best body, and be your best lover by improving personal performance in the boardroom, weightroom, or bedroom.`,
    copy: [
      `Be your best professional, build your best body, and be your best lover with performance optimization therapies from Dr. S`,
      `Whether in the boardroom, the weightroom, or the bedroom, the cellular therapies prescribed by Dr. S can help optimize performance, whatever your domain may be.`,
    ],
    _url: `performance-optimization-therapy`,
    youtube_hl: `Q&A's with Dr. S`,
    youtube_id: ['fFr2bVTeUnQ', 'Zz9fbasuHBg'],
  },
  hr: {
    headline: `Hormone Replacement`,
    body: `Combat age or illness-related cognitive and physical performance decline with hormone depletion therapies so you can make the most out of each and every day.`,
    copy: [
      `Combat age or illness-related cognitive and physical performance decline with hormone depletion therapies prescribed by Dr. S`,
      `Treating symptomatic hormone depletion from a variety of disease states, as well as from menopause or manopause, the cellular therapies from Dr. S can help you rediscover your best self and make the most out of each and every day.`,
    ],
    _url: `hormone-replacement-therapy`,
    youtube_hl: `Featured Podcast Appearances`,
    youtube_id: ['meCmSWqQVl0', 'uDOkH86tIPg'],
  },
  pr: {
    headline: `Preparation & Recovery`,
    body: `Improve recovery response to injury or surgery, and optimize your athletic preparation with nutrition and peptide therapies tailored to your personal goals.`,
    copy: [
      `Enhance your athletic preparation in the gym or at home with optimal nutrition regimens, IV therapies for hydration, and other cellular preparation therapies prescribed by Dr. S.`,
      `But sometimes it’s about getting back to being active. If this is your need, the patient-tailored cellular medicine from Dr. S can help improve recovery response to injury or surgery so you can get back to your best, as quickly and safely as possible.`,
    ],
    _url: `preparation-recovery-therapy`,
    youtube_hl: `Gut Health with Dr. S`,
    youtube_id: ['goZ8aHM1gmk', 'PodBY4xgB4U'],
  },
  pageButton: `Book An Appointment` + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` + `→`,
  button:
    `Learn More` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` +
    `→`,
  youtubeButton:
    `Subscribe on YouTube` + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` + `→`,
  youtube_w: '550',
  youtube_h: '309',
  youtube_w2: '470',
  youtube_h2: '264',
  youtube_w3: '400',
  youtube_h3: '225',
  youtube_w4: '350',
  youtube_h4: '197',
  youtube_w5: '275',
  youtube_h5: '154',
  youtube_w6: '225',
  youtube_h6: '126',
  youtube_w7: '200',
  youtube_h7: '112',
};

export const testimonials = [
  {
    name: 'Jan Yanes',
    key: '1',
    body: `Dr Suzanne Ferree is a leading edge Functional Medicine Doctor that also specializes in Peptide Therapy. I have been a patient for 5 years and am absolutely confident she has my holistic overall health in mind. She understands how everything is interrelated and gets to the root CAUSE.  Many thanks Dr. S!`,
  },
  {
    name: 'G. Boyd',
    key: '2',
    body: `Dr. S is an amazing Physician and a wonderful person. She is dedicated not only to her profession but to her patients as well. She treats everyone as if they were a friend, not just a name on a chart. She spends quality time at every visit and is never rushed. I love that she is so eager to learn everything she can and keep up with everything current in Medicine. She addresses all my concerns and explains things in terms I can understand clearly. I can't imagine trusting my health to anyone else!!`,
  },
  {
    name: 'C. Stajduhar',
    key: '3',
    body: `Dr. S and her staff are wonderful at keeping me up to date on the newest things that are out there to keep me healthy and feeling great. It is not always easy as we age but if I have a problem I know they have lots of ideas on ways for me to improve my health. I have been a very happy patient of Dr. S.`,
  },
];

export const appt = {
  headline: `Book An Appointment`,
  copy: `Dr. S is helping patients across the globe optimize their performance and improve their longevity. She is currently seeing patients on a first-come, first-served basis.`,
  button:
    `Check Availability` +
    `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` +
    `→`,
};

export const footer = {
  copy: `Specializing in performance and longevity medicine, Dr. S promotes a healthy well-being though nutrition, exercise, and an industry-leading expertise in the cellular efficiency of the human body. Learn more about Dr. S.`,
};

export const emailModal = {
  headline: 'Stay Updated',
  copy2:
    'Be the first to hear about speaking dates, content launches, medical studies, and other important information from Dr. S.',
  copy: 'Join our newsletter and stay updated. Be the first to hear about speaking dates, content launches, relevant medical studies and papers, as well as other important information from Dr. S.',
  button: 'Join our mailing list' + `\xa0\xa0\xa0` + `→`,
};
