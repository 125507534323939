import React, { Linking } from 'react';
import {
	Box,
	Flex,
	Button,
	ButtonGroup,
	Spacer,
	Image,
	Link
} from '@chakra-ui/react';
import Logo from './Logo@3x.png';
import { homepage } from '../copy';
import { useHistory } from 'react-router-dom';

export const Navbar = () => {
	const history = useHistory();

	const handleClick = () => {
		window.open('mailto:jenna@vinemedical.com?subject=Consultation Request');
	};

	return (
		<Flex
			h='130px'
			zIndex='100'
			position='sticky'
			top='0'
			bg='white'
			opacity='1'
		>
			<Box className='navbar-box'>
				<Link href='/'>
					<Image src={Logo} width='550px' />
				</Link>
			</Box>
			<Spacer />
			<Box p='5' mt='3' className='navbar-right-menu'>
				<ButtonGroup spacing='10' className='navbar-link'>
					<Link href='/services' _hover={{ color: 'black' }}>
						SERVICES
					</Link>
					<Link href='/about' _hover={{ color: 'black' }}>
						ABOUT
					</Link>
					<Link href='/contact' _hover={{ color: 'black' }}>
						CONTACT
					</Link>
				</ButtonGroup>

				<Button
					onClick={handleClick}
					color='#e2796f'
					bg='rgba(0, 0, 0, 0)'
					fontFamily='soleil, sans-serif'
					letterSpacing='-.01em'
					title='support@example.com'
					fontWeight='500'
					borderColor='#e2796f'
					borderWidth='1px'
					sx={{ textDecoration: 'none' }}
					_hover={{
						bg: '#e2796f',
						color: 'white',
						borderColor: '#e2796f',
						borderWidth: '1px',
						textDecoration: 'none'
					}}
					size='md'
					borderRadius='70px'
					fontSize='14px'
					height='65px'
					width='250px'
					marginLeft='25px'
				>
					{homepage.navButton}
				</Button>
			</Box>
		</Flex>
	);
};
