import React from 'react';
import { Box, Image, Center } from '@chakra-ui/react';
import theimage from './drsMainImage.jpg';

export const MainImage = () => {
	return (
		<Center>
			<Box className='front-img'>
				<Image src={theimage} />
			</Box>
		</Center>
	);
};
