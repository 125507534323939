import React from 'react';
import { Box, HStack, IconButton, Center } from '@chakra-ui/react';
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';

export const Icons = () => {
	return (
		<Center className='mobile-bio-icons-center'>
			<HStack>
				<Box>
					<IconButton
						as={FaFacebook}
						w='25px'
						h='25px'
						color='#e2796f'
						variant='unstyled'
						_hover={{ color: '#CB5E54' }}
						onClick={() =>
							window.open(
								'https://www.facebook.com/DrSCellularMedicine',
								'_blank'
							)
						}
					/>
				</Box>
				<Box>
					<IconButton
						as={FaInstagram}
						w='25px'
						h='25px'
						color='#e2796f'
						variant='unstyled'
						_hover={{ color: '#CB5E54' }}
						onClick={() =>
							window.open(
								'https://www.instagram.com/drsturner/?hl=en',
								'_blank'
							)
						}
					/>
				</Box>
				<Box marginRight='7px'>
					<IconButton
						as={FaLinkedin}
						w='25px'
						h='25px'
						color='#e2796f'
						variant='unstyled'
						_hover={{ color: '#CB5E54' }}
						onClick={() =>
							window.open('https://www.linkedin.com/company/dr-s/', '_blank')
						}
					/>
				</Box>
				<Box>
					<IconButton
						as={FaYoutube}
						w='35px'
						h='35px'
						color='#e2796f'
						variant='unstyled'
						_hover={{ color: '#CB5E54' }}
						onClick={() =>
							window.open(
								'https://www.youtube.com/channel/UC1RFVKncx0pSqHfiTekv77A',
								'_blank'
							)
						}
					/>
				</Box>
			</HStack>
		</Center>
	);
};
