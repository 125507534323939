import React, { useEffect } from 'react';
import { ServicesPageTemplate } from '../ServicesPageTemplate';
import { _services } from '../../copy';
import image from './PrepRecovery_Img@2x.png';
import { Youtube_PR } from '../Youtube/Youtube_PR';
import { HomeDivider } from '../../Homepage/HomeDivider';
import { Appointment } from '../../Homepage/Appointment/Appointment';
import { Services } from '../Services/Services';

export const PrepRecoveryPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { pr } = _services;
	return (
		<div>
			<ServicesPageTemplate
				img={image}
				headline={pr.headline}
				copy1={pr.copy[0]}
				copy2={pr.copy[1]}
				button={_services.pageButton}
			/>
			<Youtube_PR />
			<HomeDivider />
			<Services top={`services-page-top`} />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
