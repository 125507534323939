import { Route } from 'react-router-dom';
import { Homepage } from './components/Homepage/Homepage';
import { About } from './components/About/About';
import { MobileHomepage } from './components/Mobile/Homepage/MobileHomepage';
import { MobileAbout } from './components/MobileAbout/MobileAbout';
import { Navbar } from './components/Navbar/Navbar';
import { Navbar as MobileNavbar } from './components/Mobile/Navbar/Navbar';
import { Footer } from './components/Footer/Footer';
import { Footer as MobileFooter } from './components/MobileFooter/Footer';
import { HomeDivider } from './components/Homepage/HomeDivider';
import { ServicesPage } from './components/ServicesPage/ServicesPage';
import { MobileServicesPage } from './components/MobileServicesPage/MobileServicesPage';
import { ContactPage } from './components/ContactPage/ContactPage';
import { ContactPage as MobileContactPage } from './components/MobileContactPage/ContactPage';
import { CognitivePage } from './components/ServicesPage/CognitivePage/CognitivePage';
import { PerformancePage } from './components/ServicesPage/PerformancePage/PerformancePage';
import { HormonePage } from './components/ServicesPage/HormonePage/HormonePage';
import { PrepRecoveryPage } from './components/ServicesPage/PrepRecoveryPage/PrepRecoveryPage';
import { CognitivePage as MobileCognitivePage } from './components/MobileServicesPage/CognitivePage/CognitivePage';
import { PerformancePage as MobilePerformancePage } from './components/MobileServicesPage/PerformancePage/PerformancePage';
import { HormonePage as MobileHormonePage } from './components/MobileServicesPage/HormonePage/HormonePage';
import { PrepRecoveryPage as MobilePrepRecoveryPage } from './components/MobileServicesPage/PrepRecoveryPage/PrepRecoveryPage';

import MediaQuery from 'react-responsive';

import './App.css';

function App() {
	return (
		<div>
			<MediaQuery minWidth={1377}>
				<Navbar />
			</MediaQuery>
			<MediaQuery maxWidth={1376}>
				<MobileNavbar />
			</MediaQuery>
			<MediaQuery minWidth={1088}>
				<Route exact path='/' component={Homepage} />
				<Route path='/about' component={About} />
				<Route path='/services' component={ServicesPage} />
				<Route path='/contact' component={ContactPage} />
				<Route
					path='/cognitive-optimization-therapy'
					component={CognitivePage}
				/>
				<Route
					path='/performance-optimization-therapy'
					component={PerformancePage}
				/>
				<Route path='/hormone-replacement-therapy' component={HormonePage} />
				<Route
					path='/preparation-recovery-therapy'
					component={PrepRecoveryPage}
				/>
			</MediaQuery>
			<MediaQuery maxWidth={1087}>
				<Route exact path='/' component={MobileHomepage} />
				<Route path='/about' component={MobileAbout} />
				<Route path='/services' component={MobileServicesPage} />
				<Route path='/contact' component={MobileContactPage} />
				<Route
					path='/cognitive-optimization-therapy'
					component={MobileCognitivePage}
				/>
				<Route
					path='/performance-optimization-therapy'
					component={MobilePerformancePage}
				/>
				<Route
					path='/hormone-replacement-therapy'
					component={MobileHormonePage}
				/>
				<Route
					path='/preparation-recovery-therapy'
					component={MobilePrepRecoveryPage}
				/>
			</MediaQuery>
			<MediaQuery minWidth={1061}>
				<HomeDivider />
				<Footer />
			</MediaQuery>
			<MediaQuery maxWidth={1060}>
				<MobileFooter />
			</MediaQuery>
		</div>
	);
}

export default App;
