import React from 'react';
import { Box, Text, Center, VStack } from '@chakra-ui/react';
import { appt } from '../../copy';

export const Appointment = () => {
	return (
		<Center className='appt-center'>
			<VStack className='appt-box2'>
				<Box textAlign='center'>
					<Center>
						<Text className='appt-headline'>{appt.headline}</Text>
					</Center>
				</Box>
				<Box textAlign='center'>
					<Center>
						<Text className='appt-copy'>{appt.copy}</Text>
					</Center>
				</Box>
				<Box
					as='button'
					color='#e2796f'
					bg='rgba(0, 0, 0, 0)'
					borderColor='#e2796f'
					borderWidth='1px'
					transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
					_hover={{
						bg: '#e2796f',
						color: 'white',
						borderColor: '#e2796f',
						borderWidth: '1px'
					}}
					className='appt-button'
				>
					{appt.button}
				</Box>
			</VStack>
		</Center>
	);
};
