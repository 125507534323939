import React from 'react';
import { Center, Text } from '@chakra-ui/react';

export const ServicesHeader = () => {
	return (
		<Center className='mobile-services-header'>
			<Text className='mobile-services-top'>SERVICES</Text>
		</Center>
	);
};
