import React from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { Left } from './Left/Left';
import { Right } from './Right/Right';
import { TheCenter } from './Center/TheCenter';

export const Footer = () => {
	return (
		<Center>
			<Flex className='footer-flex'>
				<Box className='footer-box-left'>
					<Left />
				</Box>
				<Spacer />
				<Box className='footer-box-center'>
					<TheCenter />
				</Box>
				<Spacer />
				<Box className='footer-box-right'>
					<Right />
				</Box>
			</Flex>
		</Center>
	);
};
