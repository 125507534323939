import React from 'react';
import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { Video } from './Video';
import { _services } from '../../copy';
import MediaQuery from 'react-responsive';

export const Youtube_PR = () => {
	const { pr } = _services;
	return (
		<Center className='youtube-center-mobile'>
			<VStack className='youtube-vstack-mobile'>
				<Box
					className='youtube-headline-mobile'
					text-decoration-skip-ink='none'
				>
					<Text>{pr.youtube_hl}</Text>
				</Box>
				<Box className='youtube-videos-mobile'>
					<Center>
						<Box>
							<MediaQuery minWidth={700}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h}
										w={_services.youtube_w}
									/>
								</Box>
								<Box marginTop='55px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h}
										w={_services.youtube_w}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={699} minWidth={601}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h2}
										w={_services.youtube_w2}
									/>
								</Box>
								<Box marginTop='55px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h2}
										w={_services.youtube_w2}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={600} minWidth={501}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h3}
										w={_services.youtube_w3}
									/>
								</Box>
								<Box marginTop='55px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h3}
										w={_services.youtube_w3}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={500} minWidth={401}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h4}
										w={_services.youtube_w4}
									/>
								</Box>
								<Box marginTop='40px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h4}
										w={_services.youtube_w4}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={400} minWidth={361}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h5}
										w={_services.youtube_w5}
									/>
								</Box>
								<Box marginTop='40px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h5}
										w={_services.youtube_w5}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={360} minWidth={300}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h6}
										w={_services.youtube_w6}
									/>
								</Box>
								<Box marginTop='40px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h6}
										w={_services.youtube_w6}
									/>
								</Box>
							</MediaQuery>
							<MediaQuery maxWidth={299}>
								<Box>
									<Video
										id={pr.youtube_id[0]}
										h={_services.youtube_h7}
										w={_services.youtube_w7}
									/>
								</Box>
								<Box marginTop='40px'>
									<Video
										id={pr.youtube_id[1]}
										h={_services.youtube_h7}
										w={_services.youtube_w7}
									/>
								</Box>
							</MediaQuery>
						</Box>
					</Center>
				</Box>
				<Box classname='youtube-button-box-mobile'>
					<Box
						onClick={() =>
							window.open(
								'https://www.youtube.com/channel/UC1RFVKncx0pSqHfiTekv77A/?sub_confirmation=1',
								'_blank'
							)
						}
						as='button'
						color='#e2796f'
						bg='rgba(0, 0, 0, 0)'
						borderColor='#e2796f'
						borderWidth='1px'
						transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
						_hover={{
							bg: '#e2796f',
							color: 'white',
							borderColor: '#e2796f',
							borderWidth: '1px'
						}}
						className='youtube-button-mobile'
					>
						{_services.youtubeButton}
					</Box>
				</Box>
			</VStack>
		</Center>
	);
};
