import React from 'react';
import { Box, Text, Center, VStack, Link } from '@chakra-ui/react';
import contactimg from './Contact_Img@2x.png';
import { Linking, Alert, Platform } from 'react-native';
import { contact } from '../copy';

export const ContactPage = () => {
	const callNumber = (phone) => {
		console.log('callNumber ----> ', phone);
		let phoneNumber = phone;
		if (Platform.OS !== 'android') {
			phoneNumber = `telprompt:${phone}`;
		} else {
			phoneNumber = `tel:${phone}`;
		}
		Linking.canOpenURL(phoneNumber)
			.then((supported) => {
				if (!supported) {
					Alert.alert('Phone number is not available');
				} else {
					return Linking.openURL(phoneNumber);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleClick = () => {
		callNumber(4044463600);
	};

	const handleEmail = () => {
		window.open('mailto:jenna@vinemedical.com');
	};

	return (
		<Center className='contact-center'>
			<Box
				className='contact-box'
				style={{ backgroundImage: `url(${contactimg})` }}
			>
				<Box className='contact-box2'>
					<VStack align='left' className='contact-content'>
						<Box>
							<Text className='contact-headline'>{contact.headline}</Text>
						</Box>
						<Box>
							<Text className='contact-copy'>{contact.copy[0]}</Text>
						</Box>
						<Box>
							<Text className='contact-copy'>
								{contact.copy[1]}
								<Link
									onClick={handleEmail}
									color='#e2796f'
									_hover={{ color: '#CB5E54' }}
								>
									jenna@vinemedical.com
								</Link>
							</Text>
						</Box>
						<Box>
							<Box
								marginLeft='-10px'
								marginRight='30px'
								as='button'
								onClick={handleClick}
								color='#e2796f'
								bg='rgba(0, 0, 0, 0)'
								borderColor='#e2796f'
								borderWidth='1px'
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								_hover={{
									bg: '#e2796f',
									color: 'white',
									borderColor: '#e2796f',
									borderWidth: '1px'
								}}
								className='contact-button'
							>
								{contact.button1}
							</Box>
						</Box>
					</VStack>
				</Box>
			</Box>
		</Center>
	);
};

/*<HStack spacing={5} className='contact-hstack'>
							<Box>
								<Box
									as='button'
									color='#e2796f'
									bg='rgba(0, 0, 0, 0)'
									borderColor='#e2796f'
									borderWidth='1px'
									transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
									_hover={{
										bg: '#e2796f',
										color: 'white',
										borderColor: '#e2796f',
										borderWidth: '1px'
									}}
									className='contact-button'
								>
									{contact.button1}
								</Box>
								<Box></Box>
								<Box
									as='button'
									color='#e2796f'
									bg='rgba(0, 0, 0, 0)'
									borderColor='#e2796f'
									borderWidth='1px'
									transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
									_hover={{
										bg: '#e2796f',
										color: 'white',
										borderColor: '#e2796f',
										borderWidth: '1px'
									}}
									className='contact-button'
								>
									{contact.button2}
								</Box>
							</Box>
						</HStack>*/
