import React from 'react';
import { Bio } from './Bio/Bio';
import { BioMel } from './Bio/BioMel';
import { Appointment } from './Appointment/Appointment';
import { HomeDivider } from '../Homepage/HomeDivider';

export const MobileAbout = () => {
	return (
		<div>
			<Bio />
			<BioMel />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
