import React from 'react';
import quote1 from './quote_rightside@3x.png';
import quote2 from './quote_upside@3x.png';
import { Testimonial } from './Testimonial';
import { Center, Box, VStack } from '@chakra-ui/react';
import { testimonials } from '../../../copy';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';
import MediaQuery from 'react-responsive';

export const Testimonials = () => {
	return (
		<Center className='testimonials-center'>
			<VStack>
				<Box>
					<Carousel indicators={false} controls={false}>
						<Carousel.Item>
							<Box className='testimonials-box'>
								<Testimonial
									img1={quote1}
									img2={quote2}
									name={testimonials[0].name}
									testbody={testimonials[0].body}
								/>
							</Box>
						</Carousel.Item>
						<Carousel.Item>
							<Box className='testimonials-box'>
								<Testimonial
									img1={quote1}
									img2={quote2}
									name={testimonials[1].name}
									testbody={testimonials[1].body}
								/>
							</Box>
						</Carousel.Item>
						<Carousel.Item>
							<Box className='testimonials-box'>
								<Testimonial
									img1={quote1}
									img2={quote2}
									name={testimonials[2].name}
									testbody={testimonials[2].body}
								/>
							</Box>
						</Carousel.Item>
					</Carousel>
				</Box>
				<Box>
					<MediaQuery minWidth={769}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='650px'
								height='366px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={768} minWidth={692}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='550px'
								height='309px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={691} minWidth={556}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='450px'
								height='253px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={555} minWidth={426}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='350px'
								height='197px'
							/>
						</Center>
					</MediaQuery>
					<MediaQuery maxWidth={425}>
						<Center className='test-video'>
							<ReactPlayer
								url='https://vimeo.com/540717791'
								width='250px'
								height='141px'
							/>
						</Center>
					</MediaQuery>
				</Box>
			</VStack>
		</Center>
	);
};
