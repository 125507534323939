import React from 'react';
import { Center, Box, VStack, Text, Image } from '@chakra-ui/react';

export const Service = (props) => {
	return (
		<Center>
			<VStack>
				<Center>
					<Image src={props.img} className='service-img' />
				</Center>
				<Box textAlign='center'>
					<Center>
						<Text className='service-headline'>{props.headline}</Text>
					</Center>
				</Box>

				<Center>
					<Text className='service-body'>{props.copy}</Text>
				</Center>

				<Center>{props.button}</Center>
			</VStack>
		</Center>
	);
};
