import React from 'react';
import { VStack, Box, Text, Link } from '@chakra-ui/react';
export const Left = () => {
	return (
		<VStack>
			<Box>
				<Text className='footer-headline'>Explore</Text>
			</Box>
			<Link
				className='footer-link'
				_hover={{ color: 'black' }}
				href='/services'
			>
				Services
			</Link>
			<Link className='footer-link' _hover={{ color: 'black' }} href='/about'>
				About
			</Link>
			<Link className='footer-link' _hover={{ color: 'black' }} href='/contact'>
				Contact
			</Link>
		</VStack>
	);
};
