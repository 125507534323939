import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';

export const Come = () => {
	return (
		<VStack>
			<Box>
				<Text className='mobile-footer-headline'>Come see us</Text>
			</Box>
			<Text className='mobile-footer-text'>11660 Alpharetta Hwy #290</Text>
			<Text className='mobile-footer-text'>Roswell, GA 30076</Text>
			<Text className='mobile-footer-text'>(404) 446-3600</Text>
		</VStack>
	);
};
