import React from 'react';
import { HStack, Center, Box } from '@chakra-ui/react';
import { Service } from './Service';
import COimg from './Images/CO@3x.png';
import POimg from './Images/PO@3x.png';
import { _services } from '../../copy';
import { ServicesButton } from './ServicesButton';

export const Services_COPO = () => {
	const { co, po } = _services;

	return (
		<Center>
			<HStack>
				<Box className='service-hstack-box hstack-left'>
					<Service
						img={COimg}
						headline={co.headline}
						copy={co.body}
						button={<ServicesButton url={co._url} />}
					/>
				</Box>

				<Box className='service-hstack-box hstack-right'>
					<Service
						img={POimg}
						headline={po.headline}
						copy={po.body}
						button={<ServicesButton url={po._url} />}
					/>
				</Box>
			</HStack>
		</Center>
	);
};
