import React from 'react';
import { Center } from '@chakra-ui/react';
import { MainContent } from './MainContent';

export const MainPod = () => {
	return (
		<Center className='main-pod-mobile'>
			<MainContent />
		</Center>
	);
};
