import React, { useEffect } from 'react';
import { ServicesPageTemplate } from '../ServicesPageTemplate';
import { _services } from '../../copy';
import image from './Hormone_Img@2x.png';
import { Youtube_HR } from '../Youtube/Youtube_HR';
import { HomeDivider } from '../../Homepage/HomeDivider';
import { Appointment } from '../../Homepage/Appointment/Appointment';
import { Services } from '../Services/Services';

export const HormonePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { hr } = _services;
	return (
		<div>
			<ServicesPageTemplate
				img={image}
				headline={hr.headline}
				copy1={hr.copy[0]}
				copy2={hr.copy[1]}
				button={_services.pageButton}
			/>
			<Youtube_HR />
			<HomeDivider />
			<Services top={`services-page-top`} />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
