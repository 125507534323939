import React from 'react';
import {
	VStack,
	Box,
	Text,
	Link,
	Flex,
	Spacer,
	Center
} from '@chakra-ui/react';
export const Explore = () => {
	return (
		<VStack align='left'>
			<Center>
				<Box>
					<Text className='mobile-footer-headline'>Explore</Text>
				</Box>
			</Center>
			<Center>
				<Flex width='300px'>
					<Link
						className='mobile-footer-link'
						_hover={{ color: 'black' }}
						href='/services'
					>
						Services
					</Link>
					<Spacer />
					<Link
						className='mobile-footer-link'
						_hover={{ color: 'black' }}
						href='/about'
					>
						About
					</Link>
					<Spacer />
					<Link
						className='mobile-footer-link'
						_hover={{ color: 'black' }}
						href='/contact'
					>
						Contact
					</Link>
				</Flex>
			</Center>
		</VStack>
	);
};
