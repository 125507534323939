import React from 'react';
import { Center, Text } from '@chakra-ui/react';

export const TestimonialHeader = () => {
	return (
		<Center className='testimonial-header-center'>
			<Text className='testimonial-header'>HEAR FROM HER CLIENTS</Text>
		</Center>
	);
};
