import React from 'react';
import { Box, VStack, Text, Image, Center } from '@chakra-ui/react';
import bio_image from './Melissa_Mobile.png';
import { biomel as bio } from '../../copy';

export const BioMel = () => {
	return (
		<Center className='mobile-about-bio-mel-center'>
			<VStack>
				<Center className='mobile-about-bio-mel-img'>
					<Image src={bio_image} />
				</Center>

				<Box textAlign='center'>
					<Text className='mobile-about-bio-mel-headline'>{bio.headline}</Text>
					<Center>
						<Text className='mobile-about-bio-mel-subhead'>{bio.subhead}</Text>
					</Center>
					<Center>
						<Text className='mobile-bio-body'>{bio.copy}</Text>
					</Center>
				</Box>
			</VStack>
		</Center>
	);
};
