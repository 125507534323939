import React from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';
import { Explore } from './Explore/Explore';
import { Come } from './Come/Come';
import { Question } from './Question/Question';
import { LogoCopy } from './LogoCopy/LogoCopy';

export const Footer = () => {
	// We listen to the resize event
	window.addEventListener('resize', () => {
		// We execute the same script as before
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});
	return (
		<Center>
			<Box className='mobile-footer-box'>
				<VStack>
					<Box className='mobile-footer-box-come'>
						<Come />
					</Box>
					<Box>
						<LogoCopy />
					</Box>

					<Box className='mobile-footer-box-explore'>
						<Explore />
					</Box>
					<Box>
						<Question />
					</Box>
				</VStack>
			</Box>
		</Center>
	);
};
