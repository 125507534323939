import React, { useEffect, useState } from 'react';
import { MainImage } from './MainImage/MainImage';
import { MainPod } from './MainPod/MainPod';
import { Services } from './Services/Services';
import { ServicesHeader } from './Services/ServicesHeader';
import { TestimonialHeader } from './Testimonials/TestimonialHeader';
import { Testimonials } from './Testimonials/Testimonials';
import { Appointment } from './Appointment/Appointment';
import { HomeDivider } from './HomeDivider';
import { Bio } from './Bio/Bio';
import { EmailModal } from './EmailModal/EmailModal';
import { Button } from '@chakra-ui/react';

export const Homepage = () => {
	const [sub, setSub] = useState(false);
	useEffect(() => {
		getSubscribe();
	}, []);

	const handleClear = () => {
		localStorage.setItem('subscribed', 'false');
		setSub(false);
	};

	const getSubscribe = () => {
		const subscribed = localStorage.getItem('subscribed');
		if (!subscribed || subscribed === 'false') {
			setSub(false);
		} else if (subscribed === 'true') {
			setSub(true);
		}
		//console.log(subscribed);
		//console.log(sub);
	};

	return (
		<div>
			<div>{sub ? null : <EmailModal />}</div>

			<MainImage />
			<MainPod />
			<HomeDivider />
			<Bio />
			<HomeDivider />
			<ServicesHeader />
			<Services />
			<HomeDivider />
			<TestimonialHeader />
			<Testimonials />
			<Appointment />
			<HomeDivider />
		</div>
	);
};
