import React, { useEffect, useState } from 'react';
import { MainImage } from './MainImage/MainImage';
import { MainPod } from './MainPod/MainPod';
import { Services } from './Services/Services';
import { ServicesHeader } from './Services/ServicesHeader';
import { TestimonialHeader } from './Testimonials/TestimonialHeader';
import { Testimonials } from './Testimonials/Testimonials';
import { Appointment } from './Appointment/Appointment';
import { HomeDivider } from './HomeDivider';
import { EmailModal } from './EmailModal/EmailModal';
import { EmailModal as MobileEmailModal } from './MobileEmailModal/EmailModal';
import { Bio } from './Bio/Bio';
import { Button } from '@chakra-ui/react';
import MediaQuery from 'react-responsive';

export const MobileHomepage = () => {
	const [sub, setSub] = useState(false);
	useEffect(() => {
		getSubscribe();
	}, []);

	const getSubscribe = () => {
		const subscribed = localStorage.getItem('subscribed');
		if (!subscribed || subscribed === 'false') {
			setSub(false);
		} else if (subscribed === 'true') {
			setSub(true);
		}
		//console.log(subscribed);
		//console.log(sub);
	};

	const handleClear = () => {
		localStorage.setItem('subscribed', 'false');
		setSub(false);
	};

	return (
		<div>
			<div>
				{sub ? null : (
					<div>
						<MediaQuery minWidth={572}>
							<EmailModal />
						</MediaQuery>
						<MediaQuery maxWidth={571}>
							<MobileEmailModal />
						</MediaQuery>
					</div>
				)}
			</div>
			<MainImage />
			<MainPod />
			<HomeDivider />
			<Bio />
			<HomeDivider />
			<ServicesHeader />
			<Services />
			<HomeDivider />
			<TestimonialHeader />
			<Testimonials />
			<HomeDivider />
			<Appointment />
		</div>
	);
};
