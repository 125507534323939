import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Center,
	Box,
	Container
} from '@chakra-ui/react';

import { LeftContent } from './LeftContent';
import { RightContent } from './RightContent';
import bgimg from './pinkleaf_small.jpg';

export const EmailModal = () => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			onOpen();
		}, 2000);
	}, []);

	const handleClose = () => {
		onClose();
	};

	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<div>
			<Modal onClose={onClose} isOpen={isOpen} isCentered size='5xl'>
				<ModalOverlay />
				<ModalContent className='modal-content' borderWidth='0px'>
					<ModalCloseButton
						color='white'
						bg='black'
						_hover={{ color: 'black', bg: 'white' }}
					/>
					<ModalBody style={{ backgroundImage: `url(${bgimg})` }}>
						<Center h='100%'>
							<Box bg='#e2796f' w='1050px' h='550px'>
								<Center>
									<Box>
										<Container>
											<LeftContent />
										</Container>
									</Box>
									<Box>
										<Container>
											<RightContent onClose={handleClose} />
										</Container>
									</Box>
								</Center>
							</Box>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
};
