import React from 'react';
import { Center, Divider } from '@chakra-ui/react';

export const HomeDivider = () => {
	return (
		<Center>
			<Divider borderColor='grey' w='85%' opacity='20%' />
		</Center>
	);
};
