import React from 'react';
import { VStack, Image, Text, Box, Center } from '@chakra-ui/react';

import { footer } from '../../copy';
import logo from './FooterLogo@3x.png';

export const LogoCopy = () => {
	return (
		<Center marginTop='50px'>
			<VStack>
				<Center className='mobile-footer-box-img'>
					<Image src={logo} className='mobile-footer-center' />
				</Center>
				<Box className='mobile-footer-box-small'>
					<Text className='mobile-footer-small-copy'>{footer.copy}</Text>
				</Box>
			</VStack>
		</Center>
	);
};
