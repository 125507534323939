import React from 'react';
import { Box } from '@chakra-ui/react';
import { _services } from '../../copy';
import { useHistory } from 'react-router-dom';

export const ServicesButton = (props) => {
	const history = useHistory();

	const routeChange = () => {
		let path = `/${props.url}`;
		history.push(path);
	};

	return (
		<Box
			onClick={routeChange}
			as='button'
			transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
			color='#e2796f'
			bg='rgba(0, 0, 0, 0)'
			borderColor='#e2796f'
			borderWidth='1px'
			_hover={{
				bg: '#e2796f',
				color: 'white',
				borderColor: '#e2796f',
				borderWidth: '1px'
			}}
			className='service-button'
		>
			{_services.button}
		</Box>
	);
};
