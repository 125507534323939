import React from 'react';
import { HStack, Image, Text, Box, Center } from '@chakra-ui/react';

import { footer } from '../../copy';
import logo from './FooterLogo@3x.png';

export const TopCenter = () => {
	return (
		<Center>
			<HStack spacing={5}>
				<Box>
					<Image src={logo} className='footer-center' />
				</Box>
				<Box className='footer-small-box'>
					<Text className='footer-small-copy'>{footer.copy}</Text>
				</Box>
			</HStack>
		</Center>
	);
};
