import React from 'react';
import { HStack, Center, Box } from '@chakra-ui/react';
import { Service } from './Service';
import HRimg from './Images/HR@3x.png';
import PRimg from './Images/PR@3x.png';
import { _services } from '../../copy';
import { ServicesButton } from './ServicesButton';

export const Services_HRPR = () => {
	const { hr, pr } = _services;

	return (
		<Center>
			<HStack>
				<Box className='service-hstack-box hstack-left'>
					<Service
						img={HRimg}
						headline={hr.headline}
						copy={hr.body}
						button={<ServicesButton />}
					/>
				</Box>
				<Box className='service-hstack-box hstack-right'>
					<Service
						img={PRimg}
						headline={pr.headline}
						copy={pr.body}
						button={<ServicesButton />}
					/>
				</Box>
			</HStack>
		</Center>
	);
};
