import React from 'react';
import { Center, Box, VStack, Text, Image } from '@chakra-ui/react';

export const Service = (props) => {
	return (
		<Center>
			<VStack>
				<Box>
					<Image src={props.img} className='service-img' />
				</Box>
				<Box>
					<Text className='service-headline'>{props.headline}</Text>
				</Box>
				<Box className='service-body-box'>
					<Text className='service-body'>{props.copy}</Text>
				</Box>
				<Box>{props.button}</Box>
			</VStack>
		</Center>
	);
};
